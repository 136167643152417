.container {
    padding: 20px 80px 50px;
    background-color: #828682;
    width: 100%;
    flex: 1;
}

.noPermission {
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.registerIdContainer {
    align-items: center;
    display: flex;
    padding-right: 20px;
}

.deleteButton {
    fill: var(--red) !important;
    cursor: pointer;
}

.deleteButton:hover{
    opacity: 60%;
    transition: 0.5s;
}

.center {
    flex-direction: row;
    display: flex;
    align-items: center
}

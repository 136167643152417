.container {
    padding: 30px;
    width: 100%;
}

.addNewListContainer {
    display: flex;
    flex-direction: column;
}

.quickActionButton:hover{
    opacity: 60%;
    transition: 0.5s;
}

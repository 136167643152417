.cell {
    padding: 0 !important;
    padding-right: 5px !important;
}
.cell a {
    text-decoration: none;
    width: 100%;
    display: flex;
}
.head {
    padding: 10px 0 !important;
}

.cell:first-child {
    padding-left: 5px  !important;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px
}

.table {
    width: 1000px
}

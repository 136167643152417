.container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px
}

.container textarea {
    text-transform: none;
}

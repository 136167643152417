.select {
    margin-left: 10px;
    height: 37px;
    min-width: 200px;
    max-width: 400px;
    background-color: #d5d5d5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.container {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.container textarea{
    text-transform: none;
}

.leftToolkit{
    height: 50px;
    flex-direction: row;
    display: flex;
    align-items: center;
}

.container {
    display: flex;
    align-items: flex-end;
}

.container p {
    margin: 0;
    flex: 1
}

.textField {
    margin: 0 30px;
    flex: 1
}

.textField:last-of-type {
    margin-right: 0;
}

.container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px
}

.table {
    width: 1000px
}

.subscriptionDateSoonExpire {
    color: var(--orange)
}

.subscriptionDateExpired {
    color: var(--red)
}

.subscriptionDatePicker {
    margin-bottom: 20px;
}

.container {
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.container .textInput{
    margin-bottom: 20px;
}
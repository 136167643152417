.container {
    display: flex;
    align-items: flex-start;
}

.itemContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
}

.hidden{
    max-height: 42px;
    overflow: hidden;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item label {
    cursor: pointer;
}

.showMoreButton {
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
}

.container {
    margin-bottom: 30px;
}

.fileDropzone {
    border: var(--grey) 1px solid;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 8px 0;
    background-color: var(--light-gray);
}

.section {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    display: grid;
}

.title {
    color: var(--grey);
}

.line {
    border-color: var(--blue-light);
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

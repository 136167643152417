.container {
    height: 80vh;
    margin: 0 50px;
    overflow: scroll;
}

.deleteButton {
    fill: var(--red) !important;
    cursor: pointer;
}

.deleteButton:hover{
    opacity: 60%;
    transition: 0.5s;
}

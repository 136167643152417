.container {
    background-color: var(--light-gray);
    padding: 30px;
    border-radius: 15px;
    width: 1200px;
    margin: 16px;
}

.wholeRegister {
    width: 200px;
}

.buttonContainer {
    margin: 0 auto 5px;
    width: 50%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    display: grid;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-start;
}

.item  label {
    color: black
}

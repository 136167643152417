.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.topBar {
    width: 100%;
    height: 60px;
    background-color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topBar a{
    color: white;
    text-decoration: none;
    margin-left: 20px;
}

.topBar .group{
    display: flex;
    align-items: center;
}

.userName {
    margin-right: 20px;
}

.userName h4 {
    font-weight: bold;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
}

.userName p {
    margin: 5px 0 0;
    font-size: 9px;
    color: white;
    text-transform: uppercase;
}

.userName p.subscriptionDateSoonExpire {
    background-color: var(--red);
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
}

.content {
    flex: 1;
    display: flex;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

